.mission-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* background-color: #f5f5f5; */
}

.mission-card {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  width: 300px;
}

button {
  width: 100%;
  padding: 10px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

p {
  text-align: left;
  font-size: 0.8rem;
  font-family: "Libre Baskerville";
}

@keyframes shake {
  0.00% {
    transform: translate3d(-0px, 0, 0);
  }
  2.50% {
    transform: translate3d(0.25px, 0, 0);
  }
  5.00% {
    transform: translate3d(-0.5px, 0, 0);
  }
  7.50% {
    transform: translate3d(0.75px, 0, 0);
  }
  10.00% {
    transform: translate3d(-1px, 0, 0);
  }
  12.50% {
    transform: translate3d(1.25px, 0, 0);
  }
  15.00% {
    transform: translate3d(-1.5px, 0, 0);
  }
  17.50% {
    transform: translate3d(1.75px, 0, 0);
  }
  20.00% {
    transform: translate3d(-2px, 0, 0);
  }
  22.50% {
    transform: translate3d(2.25px, 0, 0);
  }
  25.00% {
    transform: translate3d(-2.5px, 0, 0);
  }
  27.50% {
    transform: translate3d(2.75px, 0, 0);
  }
  30.00% {
    transform: translate3d(-3px, 0, 0);
  }
  32.50% {
    transform: translate3d(3.25px, 0, 0);
  }
  35.00% {
    transform: translate3d(-3.5px, 0, 0);
  }
  37.50% {
    transform: translate3d(3.75px, 0, 0);
  }
  40.00% {
    transform: translate3d(-4px, 0, 0);
  }
  42.50% {
    transform: translate3d(4.25px, 0, 0);
  }
  45.00% {
    transform: translate3d(-4.5px, 0, 0);
  }
  47.50% {
    transform: translate3d(4.75px, 0, 0);
  }
  50.00% {
    transform: translate3d(-5px, 0, 0);
  }
  52.50% {
    transform: translate3d(5.25px, 0, 0);
  }
  55.00% {
    transform: translate3d(-5.5px, 0, 0);
  }
  57.50% {
    transform: translate3d(5.75px, 0, 0);
  }
  60.00% {
    transform: translate3d(-6px, 0, 0);
  }
  62.50% {
    transform: translate3d(6.25px, 0, 0);
  }
  65.00% {
    transform: translate3d(-6.5px, 0, 0);
  }
  67.50% {
    transform: translate3d(6.75px, 0, 0);
  }
  70.00% {
    transform: translate3d(-7px, 0, 0);
  }
  72.50% {
    transform: translate3d(7.25px, 0, 0);
  }
  75.00% {
    transform: translate3d(-7.5px, 0, 0);
  }
  77.50% {
    transform: translate3d(7.75px, 0, 0);
  }
  80.00% {
    transform: translate3d(-8px, 0, 0);
  }
  82.50% {
    transform: translate3d(8.25px, 0, 0);
  }
  85.00% {
    transform: translate3d(-8.5px, 0, 0);
  }
  87.50% {
    transform: translate3d(8.75px, 0, 0);
  }
  90.00% {
    transform: translate3d(-9px, 0, 0);
  }
  92.50% {
    transform: translate3d(9.25px, 0, 0);
  }
  95.00% {
    transform: translate3d(-9.5px, 0, 0);
  }
  97.50% {
    transform: translate3d(9.75px, 0, 0);
  }
  100% {
    transform: translate3d(10px, 0, 0);
  }
}

.shake-animation {
  animation: shake 3s cubic-bezier(0.78, 0.26, 0.85, 0.71) both;
}

.missionpoem p {
  font-size: 0.6rem;
  line-height: 0.7;

  color: #aaa;
  text-align: center;
}
