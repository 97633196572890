.App {
  text-align: center;
}


.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: #010101;
}

.App-link {
  color: #61dafb;
}
.news-flash {
  background-color: #ffefae;
  text-align: center;
  padding: 10px;
  font-size: 1.1rem;
  border-bottom: 2px solid #ccc;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.news-icon {
  margin-right: 8px;
  color: #ff6f31;
}

.news-text {
  margin: 0;
  text-align: left;
}
.slick-slider {
  width: 100%;
  margin-bottom: 2rem;
}
/* For larger screens */
.App-header p {
  font-size: 2rem;
}

.poem p {
  font-size: 1rem;
  line-height: 0.8;

  color: #aaa;
  text-align: center;
  font-family: "Serif";
}

/* For smaller screens */
@media only screen and (max-width: 768px) {
  .App-header p {
    font-size: 1rem;
  }

  .poem p {
    font-size: 0.8rem;
    line-height: 0.7;
  }

  .news-text {
    font-size: 0.8rem;
  }
}