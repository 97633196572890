.memlers-page {
  font-family: 'Georgia, serif';
  margin: 20px;
  line-height: 1.6;
  color: #333;
}

.memler-info-box {
  background-color: beige;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.memler-image {
  max-width: 40%;
  height: auto;
  margin-bottom: 20px;
}

.memlers-page h1, .memlers-page h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #444;
}

.memlers-page p {
  font-size: 1.2rem;
  margin-bottom: 16px;
}

.memlers-page dl {
  margin-bottom: 20px;
}